import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@mui/material';

import Modal from 'components/commons/Modal';

import { ILoadingProps } from './types';
import { LoadingContainer, LoadingText } from './styles';

const Loading: FunctionComponent<ILoadingProps> = (props: ILoadingProps) => {
  const { isModalMode = false, isTitleMode = false, message = '', size = 65 } = props;

  const renderLoading = () => (
    <LoadingContainer isModalMode={isModalMode} isTitleMode={isTitleMode}>
      <CircularProgress data-testid="loader" size={size} />
      {!!message && <LoadingText>{message}</LoadingText>}
    </LoadingContainer>
  );

  return isModalMode ? (
    <Modal disabled open>
      {renderLoading()}
    </Modal>
  ) : (
    renderLoading()
  );
};

export default Loading;
