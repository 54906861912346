import React, { FunctionComponent, useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import { ErrorBlock } from 'styles/input';

import { IRichTextEditorProps } from './types';
import { CustomFormGroup } from './styles';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const createEditorState = (value: string): EditorState => {
  const { contentBlocks, entityMap } = convertFromHTML(value);
  return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap));
};

const RichTextEditor: FunctionComponent<IRichTextEditorProps> = (props: IRichTextEditorProps) => {
  const {
    defaultValue,
    input: { onChange },
    className = '',
    label = '',
    meta: { touched, error },
  } = props;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const hasError = !!touched && !!error;

  useEffect(() => {
    if (defaultValue) setEditorState(createEditorState(defaultValue));
  }, [defaultValue]);

  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state);
    const text = draftToHtml(convertToRaw(state.getCurrentContent()));
    onChange(text.trim() === '<p></p>' ? '' : text);
  };

  return (
    <CustomFormGroup className={className} error={hasError}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeholder={label}
        toolbar={{
          inline: { options: ['bold', 'italic', 'underline'] },
          blockType: { options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'] },
          fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96] },
          fontFamily: { options: ['Inter'] },
          list: { options: ['unordered', 'ordered'] },
          textAlign: { options: ['left', 'center', 'right', 'justify'] },
          history: { options: ['undo', 'redo'] },
        }}
      />
      {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
    </CustomFormGroup>
  );
};

export default RichTextEditor;
