import React, { FunctionComponent, useEffect } from 'react';
import ReactModal from 'react-modal';

import { COLORS } from 'helpers/enums/colors';

import { IModalProps } from './types';
import {
  ModalContainer,
  ModalContent,
  ModalCloseButtonContainer,
  ModalCloseButton,
  ModalChildrenContainer,
} from './styles';

ReactModal.defaultStyles.overlay = {
  ...ReactModal.defaultStyles.overlay,
  backgroundColor: COLORS.modalBackground,
  zIndex: 100,
};

const Modal: FunctionComponent<IModalProps> = (props: IModalProps) => {
  const { children, disabled = false, onClose = () => {}, open } = props;

  useEffect(() => {
    // 27: Escape key
    const handleKeyUp = (event: KeyboardEvent) => event.keyCode === 27 && !disabled && onClose();
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <ModalContainer ariaHideApp={false} isOpen={open}>
      <ModalContent data-testid="modal">
        {!disabled && (
          <ModalCloseButtonContainer>
            <ModalCloseButton data-testid="modal-close-button" onClick={onClose} />
          </ModalCloseButtonContainer>
        )}
        <ModalChildrenContainer>{children}</ModalChildrenContainer>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
