import { Middleware } from 'redux';

import * as userConstants from 'redux/constants/user.constants';
import * as usersConstants from 'redux/constants/users.constants';
import * as servicesConstants from 'redux/constants/services.constants';
import * as blogsConstants from 'redux/constants/blogs.constants';

import { showSuccessNotification, showErrorNotification } from 'helpers/notifications';

const notificationMiddleware: Middleware = () => (next) => (action) => {
  const { data, error, type } = action;
  switch (type) {
    case userConstants.USER_ON_LOGIN_SUCCEEDED:
    case usersConstants.USERS_ON_DELETE_SUCCEEDED:
    case usersConstants.USERS_ON_SAVE_SUCCEEDED:
    case servicesConstants.SERVICES_ON_DELETE_SUCCEEDED:
    case servicesConstants.SERVICES_ON_SAVE_SUCCEEDED:
    case blogsConstants.BLOGS_ON_DELETE_SUCCEEDED:
    case blogsConstants.BLOGS_ON_SAVE_SUCCEEDED:
      showSuccessNotification(data.message);
      break;
    case userConstants.USER_ON_LOGIN_FAILED:
    case userConstants.USER_ON_LOGOUT_FAILED:
    case usersConstants.USERS_ON_DELETE_FAILED:
    case usersConstants.USERS_ON_GET_ALL_FAILED:
    case usersConstants.USERS_ON_GET_ONE_FAILED:
    case servicesConstants.SERVICES_ON_DELETE_FAILED:
    case servicesConstants.SERVICES_ON_GET_ALL_FAILED:
    case servicesConstants.SERVICES_ON_GET_ONE_FAILED:
    case blogsConstants.BLOGS_ON_DELETE_FAILED:
    case blogsConstants.BLOGS_ON_GET_ALL_FAILED:
    case blogsConstants.BLOGS_ON_GET_ONE_FAILED:
      showErrorNotification(error.response?.data?.message || error.message);
      break;
    case usersConstants.USERS_ON_SAVE_FAILED:
    case servicesConstants.SERVICES_ON_SAVE_FAILED:
    case blogsConstants.BLOGS_ON_SAVE_FAILED:
      if (error.response?.data?.errors)
        error.response.data.errors.forEach((message: string) => showErrorNotification(message));
      else showErrorNotification(error.response?.data?.message || error.message);
      break;
    default:
      if (error?.response?.status === 401)
        showErrorNotification(error.response?.data?.message || error.message);
      break;
  }
  return next(action);
};

export default notificationMiddleware;
