import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { ILoadingProps } from './types';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ isModalMode }: ILoadingProps) =>
    !isModalMode &&
    css`
      margin: 20px auto;
    `}

  ${({ isTitleMode }: ILoadingProps) =>
    isTitleMode &&
    css`
      margin: 0 20px;
    `}

  .MuiCircularProgress-circle {
    stroke: ${COLORS.blueLuckyPoint} !important;
  }
`;

export const LoadingText = styled.span`
  font-size: 20px;
  color: ${COLORS.gray};
  margin-top: 20px;
`;
