import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/services.constants';
import { getAll, getOne, save, softDelete } from 'services/services.services';

export function* servicesDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.SERVICES_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.SERVICES_ON_DELETE_FAILED, error });
  }
}

export function* servicesGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.SERVICES_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.SERVICES_ON_GET_ALL_FAILED, error });
  }
}

export function* servicesGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.SERVICES_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.SERVICES_ON_GET_ONE_FAILED, error });
  }
}

export function* servicesSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.SERVICES_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.SERVICES_ON_SAVE_FAILED, error });
  }
}

export function* servicesRefresh(): Generator {
  const table: any = yield select((state) => state.services.table);
  yield put({ type: constants.SERVICES_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchServices(): Generator {
  yield all([
    takeLatest(constants.SERVICES_ON_DELETE_REQUESTED, servicesDelete),
    takeLatest(constants.SERVICES_ON_GET_ALL_REQUESTED, servicesGetAll),
    takeLatest(constants.SERVICES_ON_GET_ONE_REQUESTED, servicesGetOne),
    takeLatest(constants.SERVICES_ON_SAVE_REQUESTED, servicesSave),
    takeLatest(
      [constants.SERVICES_ON_DELETE_SUCCEEDED, constants.SERVICES_ON_SAVE_SUCCEEDED],
      servicesRefresh,
    ),
  ]);
}
