import styled from 'styled-components';
import MUIDataTable from 'mui-datatables';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import Button from 'components/commons/Button';

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: ${SIZES.muiTableChange}px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${SIZES.muiTableChange}px) {
    margin-bottom: 22px;
  }
`;

export const TableTitle = styled.h1`
  margin: 0;
  color: ${COLORS.blueLuckyPoint};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: ${SIZES.muiTableChange}px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const TableActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${SIZES.muiTableChange}px) {
    flex-direction: column;
  }
`;

export const TableSearchContainer = styled.div`
  border: 1px solid ${COLORS.grayAlto};
  border-radius: 6px;

  @media (max-width: ${SIZES.muiTableChange}px) {
    margin-bottom: 25px;
  }
`;

export const TableAddButton = styled(Button)`
  && {
    margin-left: 20px;

    @media (max-width: ${SIZES.muiTableChange}px) {
      margin-left: 0;
      margin-bottom: 25px;
    }
  }
`;

export const TableFooterContianer = styled.td`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  gap: 4px;

  .MuiPagination-root {
    .MuiPagination-ul {
      gap: 4px;

      li {
        .MuiPaginationItem-previousNext {
          border: none;
          background: none;
        }
      }
    }

    .MuiPaginationItem-root {
      color: black;
      background: ${COLORS.grayAlto};
      border-radius: 6px;
      border: none;
    }

    .MuiPaginationItem-root.Mui-selected {
      color: white;
      background-color: ${COLORS.blueLuckyPoint};
    }
  }
`;

export const TablePaginationButton = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: ${COLORS.blueLuckyPoint};
`;

export const DataTable = styled(MUIDataTable)`
  && {
    box-shadow: none;

    [class*='MuiToolbar-root'] {
      display: none;
    }

    [class*='MuiTableCell-root'] {
      div,
      span {
        font-style: normal;
      }

      @media (max-width: ${SIZES.muiTableChange}px) {
        border-bottom: 1px solid ${COLORS.grayAlto};
      }
    }

    [class*='MUIDataTableBodyCell-stackedCommon'] {
      button {
        padding: 0;
        font-size: 16px;
      }
    }

    [class*='MUIDataTableBodyCell-stackedHeader'] {
      align-self: center;
      width: 150px;
      padding-right: 20px;
    }

    [class*='MuiTableCell-head'] {
      padding: 10px 16px;

      div,
      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: ${COLORS.blueLuckyPoint};
      }

      button {
        left: -16px;
        text-transform: none;
        padding: 6px 16px;
        margin: 0;
      }
    }

    [class*='MuiTableHead-root'] {
      @media (max-width: ${SIZES.muiTableChange}px) {
        display: none !important;
      }
    }

    [class*='MuiTableCell-body'] {
      padding: 19px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${COLORS.blueLuckyPoint};

      @media (max-width: ${SIZES.muiTableChange}px) {
        display: flex;
        justify-content: flex-start;
      }
    }

    [class*='MUIDataTableBodyRow-root'] {
      :last-child [class*='MuiTableCell-root'] {
        border: none;
      }

      @media (max-width: ${SIZES.muiTableChange}px) {
        display: flex !important;
        flex-direction: column;
        border-top: 2px solid ${COLORS.grayAlto};
        border-bottom: none;
      }
    }

    [class*='MUIDataTableBodyRow-responsiveStacked'] {
      @media (max-width: ${SIZES.muiTableChange}px) {
        [class*='MuiTableCell-root'] {
          border: none;
        }

        :last-child [class*='MuiTableCell-root'] {
          border: none;
        }
      }
    }
  }
`;
