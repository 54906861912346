import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import useTypedSelector from 'hooks/useTypedSelector';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useWindowSize from 'hooks/useWindowSize';
import { userSelector } from 'redux/selectors/user.selector';
import { SIDEBAR_WIDTH } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import SidebarContainer from 'containers/SidebarContainer';
import NavbarContainer from 'containers/NavbarContainer';

interface ILayoutContainerProps {
  auth?: boolean;
  children: ReactNode;
  title: string;
}

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: ${COLORS.grayAlabaster};
`;

const ViewContainer = styled.div<{ auth: boolean }>`
  width: 100%;
  margin-left: ${({ auth }) => (auth ? `${SIDEBAR_WIDTH}px` : '0')};

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: 0;
  }
`;

const LayoutContainer: FunctionComponent<ILayoutContainerProps> = (
  props: ILayoutContainerProps,
) => {
  const { auth = false, children, title } = props;
  const user = useTypedSelector(userSelector);
  useDocumentTitle(`${title} - Administro tu propiedad`);
  const { width } = useWindowSize();
  const isMobile = width < SIZES.bigTablet;

  if (auth && !user) return null;

  return (
    <GlobalContainer data-testid="global-container">
      {auth && <SidebarContainer isMobile={isMobile} />}
      <ViewContainer auth={auth}>
        {auth && <NavbarContainer title={title} />}
        {children}
      </ViewContainer>
    </GlobalContainer>
  );
};

export default LayoutContainer;
