import { AnyAction } from 'redux';

import * as constants from 'redux/constants/blogs.constants';
import { ITableUpdate } from 'types/table.types';
import { IBlogsFormData } from 'views/BlogsView/components/BlogsForm/types';

export const onDelete = (id: string): AnyAction => ({
  type: constants.BLOGS_ON_DELETE_REQUESTED,
  id,
});

export const onGetAll = (data: ITableUpdate): AnyAction => ({
  type: constants.BLOGS_ON_GET_ALL_REQUESTED,
  ...data,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.BLOGS_ON_GET_ONE_REQUESTED,
  id,
});

export const onSave = (formData: IBlogsFormData, id?: string): AnyAction => ({
  type: constants.BLOGS_ON_SAVE_REQUESTED,
  formData,
  id,
});
