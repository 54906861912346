import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import i18next from 'i18next';

import { composeValidators, fileValidation, requiredValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';

import { ViewContainer, ViewContent, Title } from 'styles/view';
import { FormContent, FormButtonContainer, FormSubmitButton } from 'styles/form';

import { IServicesFormProps } from './types';
import './i18n';

const ServicesForm: FunctionComponent<IServicesFormProps> = (props: IServicesFormProps) => {
  const { initialValues, isEditing, files, onSubmit } = props;

  return (
    <ViewContainer auth data-testid="services-form">
      <ViewContent auth>
        <Title>{i18next.t<string>('SERVICES_FORM:TITLE')}</Title>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('SERVICES_FORM:NAME')}
                name="name"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('SERVICES_FORM:DESCRIPTION')}
                maxLength={2000}
                name="description"
                render={Input}
                type="textarea"
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('SERVICES_FORM:ICON')}
                name="icon"
                preview={files?.icon}
                render={Input}
                type="file"
                validate={
                  isEditing ? fileValidation : composeValidators(requiredValidation, fileValidation)
                }
              />
              <Field
                label={i18next.t<string>('SERVICES_FORM:IMAGE')}
                name="image"
                preview={files?.image}
                render={Input}
                type="file"
                validate={
                  isEditing ? fileValidation : composeValidators(requiredValidation, fileValidation)
                }
              />
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('SERVICES_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default ServicesForm;
