import { TextField } from '@mui/material';

import { COLORS } from 'helpers/enums/colors';
import styled from 'styled-components';

export const FormGroup = styled.div`
  position: relative;
`;

export const CustomInput = styled(TextField)`
  && {
    width: 100%;
    height: fit-content;
    margin-bottom: 0px !important;

    input::placeholder {
      color: ${COLORS.grayManatee};
    }

    .MuiInputBase-input {
      padding: 7px 15px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: ${COLORS.blueLuckyPoint};
    }

    .MuiFilledInput-root {
      background-color: ${COLORS.white};
      border-radius: 6px;

      :before,
      :after {
        content: none;
      }
    }

    .MuiInputBase-input.Mui-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .MuiInputAdornment-positionStart {
      margin: 0 !important;
    }
  }
`;
