import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'DIALOG', {
  CANCEL: 'Cancel',
  CONFIRM: 'Confirm',
  TITLE: 'Confirm the action',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'DIALOG', {
  CANCEL: 'Cancelar',
  CONFIRM: 'Confirmar',
  TITLE: 'Confirmar acción',
});
