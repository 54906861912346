import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { FormGroup } from 'styles/input';

export const CustomFormGroup = styled(FormGroup)`
  .rdw-editor-wrapper {
    background-color: ${COLORS.white};
    border: 2px solid
      ${({ error }: { error: boolean }) => (error ? COLORS.redMonza : COLORS.grayManatee)};
    border-radius: 6px;
  }

  .rdw-editor-toolbar {
    border-radius: 6px;
  }

  .rdw-editor-main {
    padding: 0 20px;
    color: ${COLORS.blueLuckyPoint};
    font-family: 'Inter', Helvetica, Arial, serif;
    font-size: 16px;
    line-height: 22px;
  }

  .rdw-colorpicker-wrapper,
  .rdw-link-wrapper,
  .rdw-embedded-wrapper,
  .rdw-emoji-wrapper,
  .rdw-image-wrapper {
    display: none;
  }
`;
