import { createSelector } from 'reselect';

import { IAppState } from 'types/app.types';

const getIsMenuOpened = (state: { app: IAppState }) => state.app.isMenuOpened;

export const isMenuOpenedSelector = createSelector(
  [getIsMenuOpened],
  (isMenuOpened) => isMenuOpened,
);
