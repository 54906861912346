import { all } from 'redux-saga/effects';

import { watchBlogs } from 'redux/sagas/blogs.sagas';
import { watchServices } from 'redux/sagas/services.sagas';
import { watchUser } from 'redux/sagas/user.sagas';
import { watchUsers } from 'redux/sagas/users.sagas';

export default function* rootSaga(): Generator {
  yield all([watchBlogs(), watchServices(), watchUser(), watchUsers()]);
}
