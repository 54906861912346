import { createSelector } from 'reselect';

import { ASSETS_URL } from 'configs/configs';

import { IServicesState } from 'types/services.types';

const getItems = (state: { services: IServicesState }) => state.services.items;
const getSelectedItem = (state: { services: IServicesState }) => state.services.selectedItem;

export const servicesSelector = createSelector([getItems], (services) => services);

export const servicesFormSelector = createSelector([getSelectedItem], (service) => ({
  name: service?.name || '',
  description: service?.description || '',
}));

export const servicesFilesSelector = createSelector([getSelectedItem], (service) => ({
  icon: service?.icon ? ASSETS_URL + service.icon : '',
  image: service?.image ? ASSETS_URL + service.image : '',
}));
