import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as appConstants from 'redux/constants/app.constants';
import * as constants from 'redux/constants/user.constants';
import { initialize, login, logout } from 'services/user.services';
import { getCookie } from 'helpers/cookies';

export function* userInitialize(): Generator {
  try {
    if (getCookie('accessToken')) {
      const { data }: any = yield call(initialize);
      yield put({ type: constants.USER_ON_INITIALIZE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.USER_ON_INITIALIZE_FAILED, error });
  }
}

export function* userLogin(action: AnyAction): Generator {
  try {
    const { formData, pathname } = action;
    const { data }: any = yield call(login, formData);
    yield put({ type: constants.USER_ON_LOGIN_SUCCEEDED, data, pathname });
  } catch (error) {
    yield put({ type: constants.USER_ON_LOGIN_FAILED, error });
  }
}

export function* userLogout(): Generator {
  try {
    yield call(logout);
    yield put({ type: constants.USER_ON_LOGOUT_SUCCEEDED });
  } catch (error) {
    yield put({ type: constants.USER_ON_LOGOUT_FAILED, error });
  }
}

export function* watchUser(): Generator {
  yield all([
    takeLatest(appConstants.APP_ON_INITIALIZE_REQUESTED, userInitialize),
    takeLatest(constants.USER_ON_LOGIN_REQUESTED, userLogin),
    takeLatest(constants.USER_ON_LOGOUT_REQUESTED, userLogout),
  ]);
}
