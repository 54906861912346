import React, { FunctionComponent } from 'react';

import LogoImg from 'components/commons/Logo/assets/logo.png';

import { ILogoProps } from './types';
import { LogoLink, LogoImage } from './styles';

const Logo: FunctionComponent<ILogoProps> = () => (
  <LogoLink data-testid="logo" to="/">
    <LogoImage src={LogoImg} alt="Administro tu propiedad" />
  </LogoLink>
);

export default Logo;
