import { AxiosResponse } from 'axios';

import { get, post, remove } from 'services/api';
import { IBlogsFormData } from 'views/BlogsView/components/BlogsForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(`blogs?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`);

export const getOne = (id: string): Promise<AxiosResponse> => get(`blogs/${id}`);

export const save = (data: IBlogsFormData, id: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('introduction', data.introduction);
  formData.append('description', data.description);
  formData.append('author', data.author);
  if (data.image) formData.append('image', data.image);
  if (id) formData.append('_method', 'PUT');
  return post(`blogs${!id ? '' : `/${id}`}`, formData, { transformRequest: () => formData });
};

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`blogs/${id}`);
