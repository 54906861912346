import { AnyAction } from 'redux';

import * as constants from 'redux/constants/app.constants';

export const onInitialize = (): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_REQUESTED,
});

export const onToggleMenu = (isMenuOpened: boolean): AnyAction => ({
  type: constants.APP_ON_TOGGLE_MENU_REQUESTED,
  isMenuOpened,
});
