import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'SERVICES_VIEW', {
  TITLE: 'Services',
  NAME: 'Name',
  ACTIONS: 'Actions',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete service',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the service {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'SERVICES_VIEW', {
  TITLE: 'Servicios',
  NAME: 'Nombre',
  ACTIONS: 'Acciones',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar servicio',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el servicio {{ name }}?',
});
