import React, { FunctionComponent } from 'react';
import { Redirect, Switch, SwitchProps } from 'react-router-dom';

import Route from 'containers/RouteContainer';
import LoginContainer from 'containers/LoginContainer';
import DashboardContainer from 'containers/DashboardContainer';
import UsersContainer from 'containers/UsersContainer';
import UsersFormContainer from 'containers/UsersFormContainer';
import ServicesContainer from 'containers/ServicesContainer';
import ServicesFormContainer from 'containers/ServicesFormContainer';
import BlogsContainer from 'containers/BlogsContainer';
import BlogsFormContainer from 'containers/BlogsFormContainer';
import ErrorView from 'views/ErrorView';

const NavigationContainer: FunctionComponent<SwitchProps> = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    {/* Public Routes */}
    <Route exact path="/login" component={LoginContainer} restricted />
    {/* Private Routes */}
    <Route exact path="/dashboard" component={DashboardContainer} authenticated />
    <Route exact path="/users" component={UsersContainer} authenticated />
    <Route exact path="/users/create" component={UsersFormContainer} authenticated />
    <Route exact path="/users/:id/edit" component={UsersFormContainer} authenticated />
    <Route exact path="/services" component={ServicesContainer} authenticated />
    <Route exact path="/services/create" component={ServicesFormContainer} authenticated />
    <Route exact path="/services/:id/edit" component={ServicesFormContainer} authenticated />
    <Route exact path="/blogs" component={BlogsContainer} authenticated />
    <Route exact path="/blogs/create" component={BlogsFormContainer} authenticated />
    <Route exact path="/blogs/:id/edit" component={BlogsFormContainer} authenticated />
    {/* Default Route */}
    <Route component={ErrorView} />
  </Switch>
);

export default NavigationContainer;
