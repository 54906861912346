import { AxiosResponse } from 'axios';

import { get, post, remove } from 'services/api';
import { IServicesFormData } from 'views/ServicesView/components/ServicesForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `services?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getOne = (id: string): Promise<AxiosResponse> => get(`services/${id}`);

export const save = (data: IServicesFormData, id: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('description', data.description);
  if (data.icon) formData.append('icon', data.icon);
  if (data.image) formData.append('image', data.image);
  if (id) formData.append('_method', 'PUT');
  return post(`services${!id ? '' : `/${id}`}`, formData, { transformRequest: () => formData });
};

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`services/${id}`);
