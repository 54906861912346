import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'BLOGS_VIEW', {
  TITLE: 'Blogs',
  NAME: 'Title',
  DATE: 'Date',
  ACTIONS: 'Actions',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete blog',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the blog {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'BLOGS_VIEW', {
  TITLE: 'Blogs',
  NAME: 'Título',
  DATE: 'Fecha',
  ACTIONS: 'Acciones',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar blog',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el blog {{ name }}?',
});
