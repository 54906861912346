import React, { FunctionComponent, useState } from 'react';
import { IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

import { IActionButtonsProps, IItemAction } from './types';
import { ActionButtonsContainer, Menu, MenuItem } from './styles';

const ActionButtons: FunctionComponent<IActionButtonsProps> = (props: IActionButtonsProps) => {
  const { id, items, disabled = false } = props;
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const onMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuElement(event.currentTarget);
    setOpen(true);
  };

  const onCloseMenu = () => {
    setMenuElement(null);
    setOpen(false);
  };

  const onMenuItemClick = ({ onClick }: IItemAction) => {
    if (id && onClick) onClick(id);
    onCloseMenu();
  };

  return (
    <>
      <ActionButtonsContainer data-testid="action-buttons">
        <IconButton
          data-testid="action-buttons-button"
          disabled={disabled}
          onClick={onMenuButtonClick}
        >
          <MoreHoriz />
        </IconButton>
      </ActionButtonsContainer>
      <Menu
        anchorEl={menuElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        data-testid="menu-action-buttons"
        onBackdropClick={onCloseMenu}
        onClose={onCloseMenu}
        open={open}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {items.map((item: IItemAction) => (
          <MenuItem color={item.color} key={item.label} onClick={() => onMenuItemClick(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ActionButtons;
