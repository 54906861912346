import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import i18next from 'i18next';

import { composeValidators, fileValidation, requiredValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';
import RichTextEditor from 'components/inputs/RichTextEditor';

import { ViewContainer, ViewContent, Title } from 'styles/view';
import { FormContent, FormButtonContainer, FormSubmitButton } from 'styles/form';

import { IBlogsFormProps } from './types';
import './i18n';

const BlogsForm: FunctionComponent<IBlogsFormProps> = (props: IBlogsFormProps) => {
  const { initialValues, isEditing, files, onSubmit } = props;
  return (
    <ViewContainer auth data-testid="blogs-form">
      <ViewContent auth>
        <Title>{i18next.t<string>('BLOGS_FORM:TITLE')}</Title>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('BLOGS_FORM:NAME')}
                name="title"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('BLOGS_FORM:INTRODUCTION')}
                maxLength={65535}
                name="introduction"
                render={Input}
                type="textarea"
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('BLOGS_FORM:DESCRIPTION')}
                name="description"
                render={(args) => (
                  <RichTextEditor {...args} defaultValue={initialValues.description} />
                )}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('BLOGS_FORM:AUTHOR')}
                name="author"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('BLOGS_FORM:IMAGE')}
                name="image"
                preview={files?.image}
                render={Input}
                type="file"
                validate={
                  isEditing ? fileValidation : composeValidators(requiredValidation, fileValidation)
                }
              />
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('BLOGS_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default BlogsForm;
