import cookie from 'react-cookies';

interface IToken {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

const baseOptions = { path: '/' };

export const getCookie = (key: string): string => {
  return cookie.load(key);
};

export const setCookie = (key: string, value: string, options = {}): void => {
  cookie.save(key, value, { ...baseOptions, ...options });
};

export const removeCookie = (key: string, options = {}): void => {
  cookie.remove(key, { ...baseOptions, ...options });
};

export const saveUserCookies = (token: IToken): void => {
  const { accessToken, expiresIn, refreshToken } = token;
  setCookie('accessToken', accessToken, { maxAge: expiresIn });
  setCookie('refreshToken', refreshToken);
};

export const removeUserCookies = (): void => {
  removeCookie('accessToken');
  removeCookie('refreshToken');
};
