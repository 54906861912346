import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'SERVICES_FORM', {
  TITLE: 'Services',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  ICON: 'Icon',
  IMAGE: 'Image',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'SERVICES_FORM', {
  TITLE: 'Servicios',
  NAME: 'Nombre',
  DESCRIPTION: 'Descripción',
  ICON: 'Ícono',
  IMAGE: 'Imagen',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
