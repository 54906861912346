import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'LOGIN_VIEW', {
  LOADING: 'Logging in...',
  TITLE: 'Log in',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'LOGIN_VIEW', {
  LOADING: 'Iniciando sesión...',
  TITLE: 'Iniciar sesión',
});
