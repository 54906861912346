import { Middleware } from 'redux';

import * as userConstants from 'redux/constants/user.constants';
import * as usersConstants from 'redux/constants/users.constants';
import * as servicesConstants from 'redux/constants/services.constants';
import * as blogsConstants from 'redux/constants/blogs.constants';

import { history } from 'helpers/history';

const redirectMiddleware: Middleware = () => (next) => (action) => {
  const { error, pathname, type } = action;
  switch (type) {
    case userConstants.USER_ON_LOGIN_SUCCEEDED:
      setTimeout(() => history.push(pathname || '/'), 0);
      break;
    case userConstants.USER_ON_LOGOUT_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_FAILED:
      setTimeout(() => history.push('/login'), 0);
      break;
    case usersConstants.USERS_ON_SAVE_SUCCEEDED:
      history.push('/users');
      break;
    case servicesConstants.SERVICES_ON_SAVE_SUCCEEDED:
      history.push('/services');
      break;
    case blogsConstants.BLOGS_ON_SAVE_SUCCEEDED:
      history.push('/blogs');
      break;
    case userConstants.USER_ON_LOGIN_FAILED:
      break;
    default:
      if (error?.response?.status === 401) setTimeout(() => history.push('/'), 0);
      break;
  }
  return next(action);
};

export default redirectMiddleware;
