import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import { SelectableRows } from 'mui-datatables';
import { Pagination, PaginationItem } from '@mui/material';
import i18next from 'i18next';

import { useDebounce } from 'hooks/useDebounce';

import Loading from 'components/commons/Loading';
import SearchInput from 'components/inputs/SearchInput';

import { ITableProps } from './types';
import {
  DataTable,
  TableActionsContainer,
  TableAddButton,
  TableFooterContianer,
  TableHeaderContainer,
  TablePaginationButton,
  TableSearchContainer,
  TableTitleContainer,
  TableTitle,
} from './styles';
import './i18n';

const rowsPerPageOptions = [10, 25, 50, 100];
const selectableRows: SelectableRows = 'none';
const defaultOptions = {
  download: false,
  filter: false,
  jumpToPage: true,
  print: false,
  rowsPerPageOptions,
  selectableRows,
  selectableRowsHeader: false,
  serverSide: true,
  tableId: 'table',
  viewColumns: false,
};

const Table: FunctionComponent<ITableProps> = (props: ITableProps) => {
  const {
    columns,
    count,
    data,
    loading,
    onAddClick,
    onRowClick,
    onTableUpdate,
    options = {},
    table,
    title,
  } = props;
  const { limit = rowsPerPageOptions[0], page, sortKey, sortType } = table;
  const [search, setSearch] = useState<string>('');
  const debouncedValue = useDebounce<string>(search);

  useEffect(() => {
    onTableUpdate({ ...table, search, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onColumnSortChange = (changedColumn: string, direction: 'asc' | 'desc') => {
    onTableUpdate({ ...table, sortKey: changedColumn, sortType: direction });
  };

  const onPaginationChange = (event: ChangeEvent<unknown>, selectedPage: number) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    onTableUpdate({ ...table, page: selectedPage });
  };

  const customOptions = {
    ...defaultOptions,
    ...options,
    count,
    customFooter: () =>
      count / limit > 1 &&
      count > data.length && (
        <tbody>
          <tr>
            <TableFooterContianer>
              <Pagination
                count={Math.ceil(count / limit)}
                onChange={onPaginationChange}
                page={page}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: () => (
                        <TablePaginationButton data-testid="pagination-previous">
                          {i18next.t<string>('TABLE:PREVIOUS')}
                        </TablePaginationButton>
                      ),
                      next: () => (
                        <TablePaginationButton data-testid="pagination-next">
                          {i18next.t<string>('TABLE:NEXT')}
                        </TablePaginationButton>
                      ),
                    }}
                    {...item}
                  />
                )}
                shape="rounded"
                variant="outlined"
              />
            </TableFooterContianer>
          </tr>
        </tbody>
      ),
    onColumnSortChange,
    onRowClick,
    rowsPerPage: limit,
    sortOrder: { direction: sortType, name: sortKey },
    textLabels: {
      body: {
        noMatch: i18next.t<string>(`TABLE:${loading ? 'FETCHING_DATA' : 'NO_MATCH'}`),
      },
    },
  };

  return (
    <>
      <TableHeaderContainer>
        <TableTitleContainer>
          <TableTitle>{title}</TableTitle>
          {loading && <Loading isTitleMode size={25} />}
        </TableTitleContainer>
        <TableActionsContainer>
          <TableSearchContainer>
            <SearchInput
              input={{
                name: 'search',
                onChange: (value: string) => setSearch(value),
                placeholder: i18next.t<string>('TABLE:SEARCH_PLACEHOLDER'),
                value: search,
              }}
            />
          </TableSearchContainer>
          {onAddClick && (
            <TableAddButton onClick={onAddClick}>
              {i18next.t<string>('TABLE:CREATE')}
            </TableAddButton>
          )}
        </TableActionsContainer>
      </TableHeaderContainer>
      <DataTable columns={columns} data={data} options={customOptions} title={title} />
    </>
  );
};

export default Table;
