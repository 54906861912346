import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import i18next from 'i18next';

import { composeValidators, emailValidation, requiredValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';

import { FormContent, FormButtonContainer, FormSubmitButton } from 'styles/form';

import { ILoginFormProps } from './types';
import { ForgotPasswordLink } from './styles';
import './i18n';

const LoginForm: FunctionComponent<ILoginFormProps> = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <FormContent data-testid="login-form" onSubmit={handleSubmit}>
        <Field
          label={i18next.t('LOGIN_FORM:EMAIL')}
          name="email"
          render={Input}
          type="email"
          validate={composeValidators(requiredValidation, emailValidation)}
        />
        <Field
          label={i18next.t('LOGIN_FORM:PASSWORD')}
          name="password"
          render={Input}
          type="password"
          validate={requiredValidation}
        />
        <FormButtonContainer>
          <FormSubmitButton disabled={pristine || submitting} type="submit">
            {i18next.t<string>('LOGIN_FORM:SUBMIT_BUTTON')}
          </FormSubmitButton>
          <ForgotPasswordLink data-testid="forgot-password-link" to="/forgot-password">
            {i18next.t<string>('LOGIN_FORM:FORGOT_PASSWORD')}
          </ForgotPasswordLink>
        </FormButtonContainer>
      </FormContent>
    )}
  />
);

export default LoginForm;
