import { AnyAction } from 'redux';

import * as constants from 'redux/constants/user.constants';
import { ILoginFormData } from 'views/LoginView/types';

export const onLogin = (formData: ILoginFormData, pathname?: string): AnyAction => ({
  type: constants.USER_ON_LOGIN_REQUESTED,
  formData,
  pathname,
});

export const onLogout = (): AnyAction => ({
  type: constants.USER_ON_LOGOUT_REQUESTED,
});
