import { combineReducers } from 'redux';

import appReducer from 'redux/reducers/app.reducer';
import blogsReducer from 'redux/reducers/blogs.reducer';
import servicesReducer from 'redux/reducers/services.reducer';
import userReducer from 'redux/reducers/user.reducer';
import usersReducer from 'redux/reducers/users.reducer';

const rootReducer = combineReducers({
  app: appReducer,
  blogs: blogsReducer,
  services: servicesReducer,
  user: userReducer,
  users: usersReducer,
});

export default rootReducer;
