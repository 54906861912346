import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'BLOGS_FORM', {
  TITLE: 'Blogs',
  NAME: 'Title',
  INTRODUCTION: 'Introduction',
  DESCRIPTION: 'Description',
  AUTHOR: 'Author',
  IMAGE: 'Image',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'BLOGS_FORM', {
  TITLE: 'Blogs',
  NAME: 'Título',
  INTRODUCTION: 'Introducción',
  DESCRIPTION: 'Descripción',
  AUTHOR: 'Autor',
  IMAGE: 'Imagen',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
