import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'NAVBAR', {
  EDIT_PROFILE: 'Edit profile',
  LOG_OUT: 'Log out',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'NAVBAR', {
  EDIT_PROFILE: 'Editar perfil',
  LOG_OUT: 'Cerrar sesión',
});
