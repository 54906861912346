import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IBlogsFormData } from 'views/BlogsView/components/BlogsForm/types';
import { onGetOne, onSave } from 'redux/actions/blogs.actions';
import { blogsFormSelector, blogsFilesSelector } from 'redux/selectors/blogs.selector';

import LayoutContainer from 'containers/LayoutContainer';
import BlogsForm from 'views/BlogsView/components/BlogsForm';
import Loading from 'components/commons/Loading';

import 'views/BlogsView/components/BlogsForm/i18n';

const BlogsFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(blogsFormSelector);
  const files = useTypedSelector(blogsFilesSelector);
  const { loading } = useTypedSelector((state) => state.blogs);
  const isEditing = !!id;

  useEffect(() => {
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IBlogsFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('BLOGS_FORM:TITLE')}>
      <BlogsForm
        initialValues={initialValues}
        isEditing={isEditing}
        files={files}
        onSubmit={onSubmit}
      />
      {loading && <Loading isModalMode message={i18next.t('BLOGS_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default BlogsFormContainer;
