import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'USERS_FORM', {
  TITLE: 'Users',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  PASSWORD_CONFIRMATION: 'Confirm password',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'USERS_FORM', {
  TITLE: 'Usuarios',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  EMAIL: 'Email',
  PASSWORD: 'Contraseña',
  PASSWORD_CONFIRMATION: 'Confirmar contraseña',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
