import { createSelector } from 'reselect';

import { ASSETS_URL } from 'configs/configs';
import { getFormattedDate } from 'helpers/dates';

import { IBlogsState } from 'types/blogs.types';

const getItems = (state: { blogs: IBlogsState }) => state.blogs.items;
const getSelectedItem = (state: { blogs: IBlogsState }) => state.blogs.selectedItem;

export const blogsSelector = createSelector([getItems], (blogs) =>
  blogs.map((blog) => ({
    ...blog,
    createdAt: getFormattedDate(blog.createdAt),
  })),
);

export const blogsFormSelector = createSelector([getSelectedItem], (blog) => ({
  title: blog?.title || '',
  introduction: blog?.introduction || '',
  description: blog?.description || '',
  author: blog?.author || '',
}));

export const blogsFilesSelector = createSelector([getSelectedItem], (blog) => ({
  image: blog?.image ? ASSETS_URL + blog.image : '',
}));
