import { AnyAction } from 'redux';

import * as constants from 'redux/constants/services.constants';
import { ITableUpdate } from 'types/table.types';
import { IServicesFormData } from 'views/ServicesView/components/ServicesForm/types';

export const onDelete = (id: string): AnyAction => ({
  type: constants.SERVICES_ON_DELETE_REQUESTED,
  id,
});

export const onGetAll = (data: ITableUpdate): AnyAction => ({
  type: constants.SERVICES_ON_GET_ALL_REQUESTED,
  ...data,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.SERVICES_ON_GET_ONE_REQUESTED,
  id,
});

export const onSave = (formData: IServicesFormData, id?: string): AnyAction => ({
  type: constants.SERVICES_ON_SAVE_REQUESTED,
  formData,
  id,
});
